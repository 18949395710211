import React from 'react';

import { injectIntl } from 'react-intl-next';

import { type CreateFlagArgs } from '@atlaskit/flag';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import InfoIcon from '@atlaskit/icon/glyph/info';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { G300, P300, R300, Y300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import messages from './messages';

const ErrorWithIntl = injectIntl(({ intl }) => (
	<ErrorIcon
		primaryColor={token('color.icon.danger', R300)}
		size="medium"
		label={intl.formatMessage(messages.error)}
	/>
));

const WarningWithIntl = injectIntl(({ intl }) => (
	<WarningIcon
		primaryColor={token('color.icon.warning', Y300)}
		size="medium"
		label={intl.formatMessage(messages.warning)}
	/>
));

const InfoWithIntl = injectIntl(({ intl }) => (
	<InfoIcon
		primaryColor={token('color.icon.discovery', P300)}
		size="medium"
		label={intl.formatMessage(messages.info)}
	/>
));

const SuccessWithIntl = injectIntl(({ intl }) => (
	<SuccessIcon
		primaryColor={token('color.icon.success', G300)}
		size="medium"
		label={intl.formatMessage(messages.success)}
	/>
));

export const errorToastProps = (): Omit<CreateFlagArgs, 'title'> => ({
	icon: <ErrorWithIntl />,
	appearance: 'normal' as const,
});

export const warningToastProps = (): Omit<CreateFlagArgs, 'title'> => ({
	icon: <WarningWithIntl />,
	appearance: 'normal' as const,
});

export const infoToastProps = (): Omit<CreateFlagArgs, 'title'> => ({
	icon: <InfoWithIntl />,
	appearance: 'normal' as const,
});

export const successToastProps = (): Omit<CreateFlagArgs, 'title'> => ({
	icon: <SuccessWithIntl />,
	appearance: 'normal' as const,
	isAutoDismiss: true,
});
